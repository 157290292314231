import { useState, useEffect } from 'react';

/**
 * Execute callback on mount and pass data once it is available.
 * @param {fetch: () => Promise<T>} fetch callback to execute on cDM.
 */
export const useFetchData = <T>(fetchCallback: () => Promise<T>) => {
	const [fetchData, setFetchData] = useState<T>(undefined);
	const [isError, setIsError] = useState<string | boolean>(undefined);

	useEffect(() => {
		const getData = async () => {
			try {
				const res = await fetchCallback();
				setFetchData(res);
				setIsError(false)
			} catch (e) {
				setIsError(e.message || true);
			}
		};

		getData();
	}, [fetchCallback]);

	return [fetchData, isError] as [void | T, string | boolean];
};
