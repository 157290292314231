import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { OrderPayload } from 'acme-ticketing-client';

import { useFetchData } from './hooks/useFetchData';
import { getOrderIdFromUuid } from '../services/ticketingService';
import { BarnesLogo } from '../assets/icons';
import config from '../config';
import localBackgroundSrc from '../assets/images/rousseau.jpg';
import '../assets/scss/components/home.scss';
import { bem } from '../util/bem';
import { SpinnerSuspense } from './spinner';
import { Error } from './error';

const S3_BACKGROUND_SRC = "https://barnes-assets.s3.amazonaws.com/Member-Site/rousseau-woman-walking-in-an-exotic-forest.jpg";

// Determine if we are using local file or s3 depending on environment
const backgroundSrc =
	config.env === 'production' ? S3_BACKGROUND_SRC : localBackgroundSrc;

/** A custom hook that builds on useLocation to parse. */
const getQueryString: () => URLSearchParams = () =>
	new URLSearchParams(window.location.search);

const ADMISSION = "ADMISSION";
const CLASS = "CLASS"
const TOUR = "TOUR"

// eslint-disable-next-line @typescript-eslint/no-redeclare
type ADMISSION = typeof ADMISSION;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type CLASS = typeof CLASS;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type TOUR = typeof TOUR;

export const Home: React.FC = () => {
	// Memoize uuid and callback to fetch order id.
	const uuid = useMemo(() => getQueryString().get('uuid'), []);
	const queryStringOrderNumber = useMemo(
		() => getQueryString().get('orderNumber'),
		[]
	);

	const fetchOrderId = useCallback(async () => {
		if (uuid) {
			return getOrderIdFromUuid(uuid);
		}
	}, [uuid]);

	// Fetch information, check error state.
	const [order, error] = useFetchData<OrderPayload>(fetchOrderId);

	// Set isLoaded to true when order or error is defined
	const [isLoaded, setIsLoaded] = useState(false);
	const [showError, setShowError] = useState<string | boolean>(false)
	useEffect(() => {
		if (order !== undefined && error !== undefined) {
			setIsLoaded(true)
		}
		if (error) {
			setShowError(error);
		}
	}, [order, error])

	// Timeout for showing error when an order isn't found
	useEffect(() => {
		const loadTimeout = setTimeout(() => {
			if (!isLoaded) {
				setShowError(true);
				setIsLoaded(true);
			}
		}, 5000);

		return function cleanup() {
			clearTimeout(loadTimeout);
		}
	}, [isLoaded]);

	// Ticket type for determining copy
	const ticketType: ADMISSION | CLASS | TOUR | null = useMemo(() => {
		const itemTypeName = order && order?.eventItems[0]?.items[0]?.itemTypeName
		if (itemTypeName?.includes("Admission")) {
			return ADMISSION
		} else if (itemTypeName?.includes("Class")) {
			return CLASS
		} else if (itemTypeName?.includes("Tour")) {
			return TOUR
		} else {
			return null
		}
	}, [order])

	// Reconcile against querystring param, fetch, and default.
	const orderNumber =
		queryStringOrderNumber || (order && order.orderNumber) || undefined;

	// Get className
	const headerClassName = bem(
		'home',
		'header',
		Boolean(!queryStringOrderNumber && !order) && 'hidden'
	);
	const subHeaderClassName = bem(
		'home',
		'sub-header',
		Boolean(!queryStringOrderNumber && !order) && 'hidden'
	);

	// Page content
	const header = useMemo(() => order ? order?.eventItems[0]?.items[0]?.itemTypeName : "Gift of the Barnes", [order]);
	const boxOfficeCopy = `Have your Order Number and Ticket number ready, and a box 
	office agent will be happy to assist you between 10am and 4:30pm ET, 7 days a week.`

	const classCopy = `<p><strong><em>Ready to be inspired?</em></strong></p>
	<p><a href="https://www.barnesfoundation.org/classes" target="blank">
	Peruse our menu of current and upcoming classes
	</a>
	 and decide which one you’d like to put your
	 gift voucher toward. Barnes Classes are offered on-site, online, or in an innovative 
	 format that allows students from all over the world to learn together. With a diverse 
	 array of topics and learning formats, there is a Barnes Class for everyone.</p>
	<p>This voucher is redeemable for any 4-week Barnes Class valued at $220. To redeem 
	your Barnes Class voucher, call <a href="tel:2152787200">215-278-7200</a>. ${boxOfficeCopy}</p>`

	const admissionCopy = `
	<p><strong><em>Ready to be inspired?</em></strong></p>
	<p>Please call <a href="tel:2152787200">215-278-7200</a> to redeem your gift voucher 
	for general admission to the 
	Barnes Foundation. ${boxOfficeCopy}</p> 
	<p><a href="https://www.barnesfoundation.org/plan-your-visit" target="blank">
	Plan your visit and learn other tips to make the most of your trip to the Barnes
	</a>. We look forward to seeing you in the galleries!</p>`

	const tourCopy = `
	<p><strong><em>Ready to be inspired?</em></strong></p>
	<p>Please call <a href="tel:2152787200">215-278-7200</a> to redeem your gift 
	voucher for a private tour at the Barnes Foundation. ${boxOfficeCopy} Tours 
	must be requested at least four days in advance and may have up to 8 guests,
	each with a voucher.​</p>`


	const copy = useMemo(() => {
		if (ticketType) {
			if (ticketType === CLASS) {
				return classCopy
			} else if (ticketType === ADMISSION) {
				return admissionCopy
			}  else if (ticketType === TOUR) {
				return tourCopy
			}
		} else {
			return `<p><strong><em>Ready to be inspired?</em></strong></p><p>To redeem your gift voucher, call 215-278-7200 
			and have your Order Number and Ticket Number ready, and a box office agent will be 
			happy to assist you between 10am and 4:30pm ET, 7 days a week.</p>`
		}
	}, [ticketType, classCopy, admissionCopy, tourCopy])

	return (
		<section className="home__flex">
			{/* TODO: wrap all of this in a loading screen */}
			<img className="home__background" src={backgroundSrc} alt="" />
			<div className="home__overlay"></div>
			<div className="home">
				<a href="https://www.barnesfoundation.org">
					<BarnesLogo className="home__logo" />
				</a>
				<SpinnerSuspense isLoaded={isLoaded}>
					<>
						{showError ? (
							<Error />
						) : (
							<div className="home__content">
								<h1 className={headerClassName}>
									{header}
								</h1>

								<h2 className={subHeaderClassName}>Order Number: {orderNumber}</h2>
								<h3 className="home__zeta home__sub-header home__sub-header--small">
									{uuid ? `Ticket number: ${uuid}` : ''}
								</h3>

								<div className="home__copy" dangerouslySetInnerHTML={{ __html: copy }}></div>

								<a className="home__call" href="tel:2152787200">
									Call 215.278.7200
								</a>
							</div>
						)}
					</>
				</SpinnerSuspense>
			</div >
		</section >
	);
};
