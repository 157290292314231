// Additional params to keep a consistent option bag approach.
type AdditionalFetchParams = {
	url: string;
	data?: { [key: string]: any };
	isRedirect?: boolean;
};
/**
 * Wrapper around the Fetch browser API.
 * @param {RequestInit & AdditionalFetchParams} init Request object with additional params for convenience.
 * @returns {Promise<T>} Object of generic type from URL endpoint.
 */
export const executePayloadRequest = async <T>(
	request: RequestInit & AdditionalFetchParams
): Promise<T> => {
	try {
		const res: Response = await fetch(request.url, {
			// Default values for request.
			body: request.data ? JSON.stringify(request.data) : undefined,
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
			// Overwrite any defaults in option bag.
			...request,
		});

		const json = await res.json();

		// Handle 500 status.
		if (res.status === 500) {
			throw json;
		}

		// Cast as <T> and return.
		return json as T;
	} catch (e) {
		throw e;
	}
};
