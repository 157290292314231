import React from 'react';
import {
	FacebookIcon,
	InstagramIcon,
	TwitterIcon,
	YoutubeIcon,
} from '../assets/icons';
import '../assets/scss/components/footer.scss';

const BARNES_FOUNDATION_URL = 'https://barnesfoundation.org/';
const SOCIAL_LINKS = [
	{
		url: 'https://twitter.com/the_barnes',
		icon: <TwitterIcon className="footer__icon" />,
	},
	{
		url: 'http://www.facebook.com/barnesfoundation',
		icon: <FacebookIcon className="footer__icon" />,
	},
	{
		url: 'http://www.youtube.com/barnesfoundation',
		icon: <YoutubeIcon className="footer__icon" />,
	},
	{
		url: 'https://instagram.com/barnesfoundation/',
		icon: <InstagramIcon className="footer__icon" />,
	},
];

export const Footer = () => (
	<footer className="app__footer footer">
		{/** Internal links */}
		<a
			rel="noopener noreferrer"
			target="_blank"
			className="footer__link"
			href={BARNES_FOUNDATION_URL}
		>
			The Barnes Foundation
		</a>

		{/** Social icons */}
		<nav className="footer__social" aria-labelledby="socialnav-heading">
			{SOCIAL_LINKS.map(({ url, icon }) => (
				<a
					key={url}
					className="footer__link footer__link--icon"
					href={url}
					rel="noopener noreferrer"
					target="_blank"
				>
					{icon}
				</a>
			))}
		</nav>
	</footer>
);
