import React, { Fragment } from "react";

import { ReactComponent as SadFace } from "./icons/sad-face.svg";
import '../assets/scss/components/error.scss';

type ErrorProps = {
    message?: string | JSX.Element;
};

export const Error: React.FC<ErrorProps> = ({ message }: ErrorProps) => {
    const defaultMessage = (
        <>
            <p>
                Oops! We&apos;re having trouble completing your request.
            </p>
            <p>
                Please contact <a href="tel:2152787200">215-278-7200</a> for assistance.
            </p>
        </>
    );

    const errorMessage = !!message ? <>{message}</> : defaultMessage;

    return (
        <div className="error">
            <SadFace className="error__sad-face" />
            <div className="error__text">
                {errorMessage}
            </div>
        </div>
    );
};
