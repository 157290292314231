import { executePayloadRequest } from './httpService';
import { OrderPayload } from 'acme-ticketing-client';

/**
 * Post array of order item UUIDs to server to get tickets.
 * @param {string[]} uuid - Order item uuids to be checked in.
 * @returns {ListEventSummariesPayload} response object from acme.
 */
export const getOrderIdFromUuid = async (uuid): Promise<OrderPayload> =>
	executePayloadRequest<OrderPayload>({
		url: '/api/ticketing',
		data: { uuid },
	});
