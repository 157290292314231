import React from 'react';
import { Home } from './components/home';
import { Footer } from './components/footer';
import './assets/scss/components/app.scss';

function App() {
	return (
		<main className="app">
			<div className="app__content">
				<Home />
			</div>
			<Footer />
		</main>
	);
}

export default App;
