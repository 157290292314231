/**
 * Convert string to BEM.
 * @param {string} block
 * @param {string} element
 * @param {string} modifier
 * @returns {string}
 */
export const bem: (
	block: string,
	element: string | void,
	modifier: string | void
) => string = (block, element, modifier) => {
	let className = block;

	if (element) {
		className = `${className}__${element}`;
	}

	if (modifier) {
		className = `${className} ${className}--${modifier}`;
	}

	return className;
};
